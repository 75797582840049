<template>
  <div class="box">
    <Banner
      :image="require('@/assets/image/appstore/appstore.svg')"
      title="应用市场"
      body=" 随着区块链技术在金融领域应用的不断验证，其技术优势在其他行业领域也逐渐体现出价值。
      目前，医疗健康、IP版权、教育、文化娱乐、通信、慈善公益、社会管理、共享经济、物联网等领域都在逐渐落地区块链应用项目，“区块链+”正在成为现实。"
    />
    <div style="display: flex; justify-content: center">
      <div class="box2">
        <el-row style="width: 100%" type="flex" justify="left">
          <el-select
            v-model="selectValue"
            placeholder="请选择标签"
            class="button-top"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-row>
        <el-row
          v-for="(item, index) in tableData"
          :key="index"
          style="width: 100%; max-width: 1400px"
        >
          <span style="min-width: 900px">
            <div v-if="index % 3 === 0">
              <el-col :span="8" class="child-table">
                <el-card
                  :body-style="{ padding: '0px' }"
                  class="box-card"
                  shadow="hover"
                >
                  <el-image
                    class="icon"
                    :src="tableData[index].logoSrc"
                    fit="cover"
                  />
                  <div class="info-top-text">
                    <p class="card-title">{{ tableData[index].name }}</p>
                    <p class="address text-overflow">
                      Address: {{ tableData[index].address }}
                    </p>
                    <div>
                      <a v-for="tag in tableData[index].tags" :key="tag">
                        <el-tag
                          size="mini"
                          effect="dark"
                          style="margin-right: 10px"
                          >{{ tag }}</el-tag
                        >
                      </a>
                    </div>
                  </div>
                  <div class="button" hidden>
                    <el-link type="primary"
                      >查看详情<i
                        class="el-icon-arrow-right"
                        style="margin-left: 5px"
                      ></i
                    ></el-link>
                    <!-- <el-button @click="detail">查看详情</el-button> -->
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8" class="child-table" v-if="tableData[index + 1]">
                <el-card
                  :body-style="{ padding: '0px' }"
                  class="box-card"
                  shadow="hover"
                >
                  <el-image
                    class="icon"
                    :src="tableData[index + 1].logoSrc"
                    fit="cover"
                  />
                  <div class="info-top-text">
                    <p class="card-title">{{ tableData[index + 1].name }}</p>
                    <p class="address">
                      Address: {{ tableData[index + 1].address }}
                    </p>
                    <div>
                      <a v-for="tag in tableData[index + 1].tags" :key="tag">
                        <el-tag
                          size="mini"
                          effect="dark"
                          style="margin-right: 10px"
                          >{{ tag }}</el-tag
                        >
                      </a>
                    </div>
                  </div>
                  <div class="button" hidden>
                    <el-link type="primary"
                      >查看详情<i
                        class="el-icon-arrow-right"
                        style="margin-left: 5px"
                      ></i
                    ></el-link>
                    <!-- <el-button @click="detail">查看详情</el-button> -->
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8" class="child-table" v-if="tableData[index + 2]">
                <el-card
                  :body-style="{ padding: '0px' }"
                  class="box-card"
                  shadow="hover"
                >
                  <el-image
                    class="icon"
                    :src="tableData[index + 2].logoSrc"
                    fit="cover"
                  />
                  <div class="info-top-text">
                    <p class="card-title">{{ tableData[index + 2].name }}</p>
                    <p class="address">
                      Address: {{ tableData[index + 2].address }}
                    </p>
                    <div>
                      <a v-for="tag in tableData[index + 2].tags" :key="tag">
                        <el-tag
                          size="mini"
                          effect="dark"
                          style="margin-right: 10px"
                          >{{ tag }}</el-tag
                        >
                      </a>
                    </div>
                  </div>
                  <div class="button" hidden>
                    <el-link type="primary">
                      查看详情
                      <i
                        class="el-icon-arrow-right"
                        style="margin-left: 5px"
                      ></i
                    ></el-link>
                    <!-- <el-button @click="detail">查看详情</el-button> -->
                  </div>
                </el-card>
              </el-col>
            </div>
          </span>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { Banner } from '@/components/common/Index.vue';

export default {
  components: {
    Banner,
  },
  data() {
    return {
      tableData: [
        {
          name: '上海市农业发展促进中心',
          address: '0x7C8E28B8A15E1B0BcE4b71304D95bEDe834361123',
          tags: ['农业', '新闻'],
          logoSrc:
            require('@/assets/image/appstore/xinnongzhibao.jpg'),
        },
        {
          name: '江苏捌仙找房网络科技有限公司',
          address: '0x7C8E28B8A15E1B0BcE4b71304D95bEDe834361123',
          tags: ['地产', '交易'],
          logoSrc:
            require('@/assets/image/appstore/aixinfangchan.jpg'),
        },
        {
          name: '南京数字金融产业研究院有限公司',
          address: '0x7C8E28B8A15E1B0BcE4b71304D95bEDe834361123',
          tags: ['金融'],
          logoSrc:
            require('@/assets/image/appstore/DFI.jpg'),
        },
        {
          name: '浙江绿迹农业科技有限公司',
          address: '0x7C8E28B8A15E1B0BcE4b71304D95bEDe834361123',
          tags: ['物联网', '农业'],
          logoSrc:
            require('@/assets/image/appstore/lvji.jpg'),
        },
        {
          name: '青岛秦曦科技有限公司',
          address: '0x7C8E28B8A15E1B0BcE4b71304D95bEDe834361123',
          tags: ['人力资源'],
          logoSrc:
            require('@/assets/image/appstore/xiaoxilinggong.jpg'),
        },
      ],
      options: [
        {
          value: '农业',
          label: '农业',
        },
        {
          value: '新闻',
          label: '新闻',
        },
        {
          value: '地产',
          label: '地产',
        },
        {
          value: '交易',
          label: '交易',
        },
        {
          value: '金融',
          label: '金融',
        },
        {
          value: '物联网',
          label: '物联网',
        },
        {
          value: '人力资源',
          label: '人力资源',
        },
      ],
      selectValue: '',
      tag: [],
    };
  },
  watch: {
    tag() {
      // 监听tag改变来发起http请求
      if (this.tag) {
        console.log(`watch tag:${this.tag}`);
      }
    },
  },
  methods: {
    detail() {
      // 点击查看详情
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
}
.child-table {
  display: flex;
  flex-wrap: wrap;
}
.box-card {
  width: 450px;
  height: 300px;
  margin: 10px;
  text-align: left;
}
.info-top-text {
  width: 80%;
  margin-left: 20px;
  line-height: 90%;
}
.icon {
  /* height: 200px; */
  width: 100%;
}
.address {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 0.4;
}
.box2 {
  width: 50%;
  margin-bottom: 20px;
  min-width: 1200px;
}
.button-top {
  margin: 20px auto 10px 10px;
}
.button {
  margin-top: 10px;
  margin-right: 16px;
  text-align: right;
}
.card-title {
  font-size: 20px;
}
</style>
